<template>

    <div class="h-8 w-full mt-3 flex flex-row justify-start items-center px-1">

        <div class="h-full w-1/4 flex flex-col justify-center items-center">
            <span class="text-xxs text-good">25%</span>
            <span class="text-font-gray text-xxs mt-1">{{ row['25'].total.sales | reduceBigNumbers(2) }} €</span> 
        </div>

        <div class="h-full w-1/4 flex flex-col justify-center items-center">
            <span class="text-xxs text-success">50%</span>
            <span class="text-font-gray text-xxs mt-1">{{ row['50'].total.sales | reduceBigNumbers(2) }} €</span> 
        </div>

        <div class="h-full w-1/4 flex flex-col justify-center items-center">
            <span class="text-xxs text-warning">75%</span>
            <span class="text-font-gray text-xxs mt-1">{{ row['75'].total.sales | reduceBigNumbers(2) }} €</span> 
        </div>

        <div class="h-full w-1/4 flex flex-col justify-center items-center">
            <span class="text-xxs text-danger">100%</span>
            <span class="text-font-gray text-xxs mt-1">{{ row['100'].total.sales | reduceBigNumbers(2) }} €</span> 
        </div>

    </div>

</template>

<script>
    export default {
        name: "TotalsByPercentage",
        props: ['row']
    }
</script>